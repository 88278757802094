.news-div {
    bottom: 0px;
    left: 0px;
    right: 0px;
    padding-left: 1rem/* 16px */;
    padding-right: 1rem/* 16px */;
    padding-top: 0.5rem/* 8px */;
    padding-bottom: 0.5rem/* 8px */;
    --tw-bg-opacity: 1;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity));
    opacity: 0.4;



    background: linear-gradient(to bottom, rgba(169,208,113,0),rgba(136,173,215,1)); 
}